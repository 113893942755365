import React, {Component} from 'react';
import {Col} from 'reactstrap';
import CountdownNow from 'react-countdown-now';
import Cookies from "js-cookie";
import classes from './Countdown.module.scss';

class Countdown extends Component{
    constructor(props) {
        super(props);
        this.state = {
            animate : false,
            animateMessage: 'Jetzt mit SMS-Code abstimmen!'
        }
    }

    daysInHours;

    componentDidUpdate() {
        if (Cookies.get('bigfoot_code-requested') && !this.state.animate) {
            this.animateHandler(true);
        } else if (!Cookies.get('bigfoot_code-requested') && this.state.animate){
            this.animateHandler(false);
        }
    }

    animateHandler = (animateValue) => {
        this.setState(({
            animate: animateValue
        }));
    }

    render() {
        let phaseDate = '';
        let countdownMessage = '';
        if(Object.keys(this.props.votingPhases).length !== 0){
            if (!this.props.votingPhases.voteStarted) {
                // the voting has NOT yet started
                phaseDate = this.props.votingPhases.voteStart;
                countdownMessage = 'bis zur Abstimmung';
            } else if (!this.props.votingPhases.voteEnded) {
                if (this.props.votingPhases.type.length > 1) {
                    // it will have a finale
                    if (this.props.votingPhases.voteStartedFinale) {
                        // the finale started
                        phaseDate = this.props.votingPhases.voteEnd;
                        countdownMessage = 'bis zum Ende';
                    } else {
                        // the finale has not yet started
                        phaseDate = this.props.votingPhases.voteStartFinale;
                        countdownMessage = 'bis zum Finale';
                    }
                } else {
                    // it will NOT have a finale
                    phaseDate = this.props.votingPhases.voteEnd;
                    countdownMessage = 'bis zum Ende';
                }
            } else {
                countdownMessage = 'ist beendet';
            }
        }

        // necessary to render the static string for the accessible, not animated variant.
        const renderMessageFromDate = ({date}) => {
            const difference = new Date(new Date(date).toUTCString()) - Date.now();
            const oneSecond = 1000;
            const oneMinute = 60 * oneSecond;
            const oneHour = 60 * oneMinute;
            const oneDay = 24 * oneHour;

            const days = Math.floor(difference / oneDay);
            const hours = Math.floor(difference / oneHour);
            const minutes = Math.floor(difference / oneMinute);
            const seconds = Math.floor(difference / oneSecond);

            return renderMessage({days, hours, minutes, seconds});
        };

        const renderMessage = ({days, hours, minutes, seconds}) => {
            if (!this.props.votingPhases.voteEnded) {
                if (days < 2) {
                    this.daysInHours = true;
                    return `Noch ${days} Tag und ${hours}:${minutes}:${seconds + ' ' + countdownMessage}`; // until vote start
                } else {
                    return `Noch ${days + ' Tage ' + countdownMessage}`;
                }
            } else {
                return "Der Wettbewerb ist beendet";
            }
        };

        const renderer = (props) => <span>{renderMessage(props)}</span>;
        return (
            <Col className={'text-white bg-primary px-0 py-1 text-center'}>
                <div className={this.state.animate ? classes.CountdownWrapper : ""}>
                    <div className={this.state.animate ? `${classes.animatedElement}  ${classes.CountdownTimer}`: ""} aria-hidden={true}>
                        <CountdownNow
                            date={phaseDate}
                            renderer={renderer}
                            daysInHours={this.daysInHours}
                        />
                    </div>
                    {this.state.animate && <p className={`${classes.animatedElement} ${classes.CountDownMessage}`} aria-hidden={true}>{this.state.animateMessage}</p>}
                    {<p className="visually-hidden">
                        {renderMessageFromDate({date: phaseDate})
                            + (this.state.animate ? ` - ${this.state.animateMessage}` : '')}
                    </p>}
                </div>
            </Col>
        );
    }


}

export default Countdown;
